import React from 'react'
import Header from '../../Layout/Header'
import Sidebar from '../../Layout/Sidebar'
import Footer from '../../Layout/Footer'
import Apis from '../../../../api'
import keyBy from 'lodash/keyBy'
import swal from 'sweetalert'
import Toolbar from './compontes/toolbar'
import { Button } from 'reactstrap'


function MaterialEquipmentLogs() {
    const [materialList, setMaterialsList] = React.useState([])
    const [equipmentList, setEquipmentList] = React.useState([])
    const [taskData, setTaskDatas] = React.useState([])
    const [teamList, setTeam] = React.useState([])
    const [taskmaterialList, setTaskMaterialList] = React.useState([])
    const [taskequipmentList, setTaskEquipmentList] = React.useState([])
    const [suppliersList, setSupplierList] = React.useState([])
    const materialById = keyBy(materialList, '_id')
    const equipmentByID = keyBy(equipmentList, '_id')
    const taskByID = keyBy(taskData, '_id')
    const teamByID = keyBy(teamList, '_id')
    const supplierById = keyBy(suppliersList, '_id')
    const [refresh, setRefresh] = React.useState(false)
    const [count, setCount] = React.useState(0);

    const [toolbarState, setToolbarState] = React.useState({
        team: '',
        order_status: '',
        date: '',
        type_code: 1
    })

    const onToolbarStateChange = ({ name, value }) => {
        setToolbarState((prev) => ({ ...(prev ?? {}), [name]: value }))
    }
    const onResetToolbarState = () => setToolbarState({
        team: '',
        order_status: '',
        date: '',
        type_code: 1
    })

    React.useEffect(() => {
        Apis.getMaterial().then((res) => {
            setMaterialsList(res.data.data.materials)
        }).catch((error) => {
            console.log(error)
        })
        Apis.getTeam().then((res) => {
            setTeam(res.data.data.team)
        }).catch(error => {
            console.log(error.response.data)
        })
        Apis.getSuppliers().then((res) => {
            setSupplierList(res.data.data.suppliers)
        }).catch((error) => {
            console.log(error)
        })
        Apis.getMaterialEquipment().then((res) => {
            setEquipmentList(res.data.data.equipment)

        }).catch((error) => {
            console.log(error)
        })

    }, [])
    React.useEffect(() => {
        Apis.getAllTaskMaterial().then((res) => {
            setTaskMaterialList(res.data.data.taskmaterials)
            setCount(res.data.data.length)
        }).catch((error) => {
            console.log(error)
        })
        Apis.getAllTaskEquipment().then((res) => {
            setTaskEquipmentList(res.data.data.taskequipments)
        }).catch((error) => {
            console.log(error)
        })
    }, [refresh])
    React.useEffect(() => {
        const filter = [{ type: 'Task' },
        { delete_status: false }]
        const raw = {
            "filter": filter
        }
        Apis.taskfilter(raw).then((res) => {
            setTaskDatas(res.data.tasks)
        }).catch((error) => {
            console.log(error.response.data)
        })

    }, [])

    const OrderMaterial = (record) => {
        swal({
            title: "Are you sure want to order this material?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((result) => {
            if (result) {
                const raw = {
                    material_id: record._id,
                    ordered_time: new Date().getTime()
                }
                Apis.placeTaskMaterialOreded(raw).then((res) => {
                    swal({
                        title: res.data.message,
                        icon: "success"
                    }).then((result) => {
                        setRefresh(!refresh)
                    })
                }).catch((error) => {
                    console.log(error)
                })
            }
        });

    }
    const OrderEquipment = (record) => {
        swal({
            title: "Are you sure want to order this equipment?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((result) => {
            if (result) {
                const raw = {
                    equipment_id: record._id,
                    ordered_time: new Date().getTime()
                }
                Apis.placeTaskEquipmentOreded(raw).then((res) => {
                    swal({
                        title: res.data.message,
                        icon: "success"
                    }).then((result) => {
                        setRefresh(!refresh)
                    })
                }).catch((error) => {
                    console.log(error)
                })
            }
        });

    }

    const getBusinessDatesCount = (startDate, endDate) => {
        let count = 0;
        let curDate = startDate;
        while (curDate < endDate) {
            const dayOfWeek = new Date(curDate).getDay();

            const isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
            if (isWeekend) {
                count++;
            }
            curDate = curDate + 24 * 60 * 60 * 1000
        }

        return count;
    }

    return (
        <>
            <div id="layout-wrapper">
                <Header title='M/E Logs | P2IC'></Header>
                <Sidebar title='Material_managment' show='MaterialEquipmentLogs'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">M/E Logs</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Material Manager</li>
                                            <li className="breadcrumb-item active">M/E Logs</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row pr_tagsrow mb-3">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-9">
                                            <Toolbar
                                                state={toolbarState}
                                                teams={teamList.map((i) => ({ value: i._id, label: i.name }))}
                                                onChange={onToolbarStateChange}
                                                onReset={onResetToolbarState}
                                            />
                                        </div>
                                        <div className="col-lg-3">
                                            <div className='container-fluid'>
                                                <div className="row align-self-end justify-content-end">
                                                    <div className="col-lg-6" style={{ display: 'contents' }}>
                                                        <Button
                                                            color="danger"
                                                            className="btn-primary btn waves-effect waves-light"
                                                            onClick={onResetToolbarState}
                                                        >
                                                            Reset
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pr_tagsrow">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-centered table-nowrap mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Work Structure</th>
                                                                    <th scope="col">Task</th>
                                                                    <th scope="col">Team</th>
                                                                    <th scope="col">Requirements</th>
                                                                    <th scope="col">Required on site (date)</th>
                                                                    <th scope="col">Lead time</th>
                                                                    <th scope="col">Required to order by (Date)</th>
                                                                    <th scope='col'>Ordered date</th>
                                                                    <th scope='col'>Type</th>

                                                                    <th scope="col">Order Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {taskmaterialList && taskmaterialList.map((list, index) => {

                                                                    var startDate = new Date(new Date(taskByID[list.task_id]?.startDate).getTime() - materialById[list.material]?.lead_time * 24 * 60 * 60 * 1000).getTime()
                                                                    var endDate = taskByID[list.task_id]?.startDate
                                                                    let day = getBusinessDatesCount(startDate, endDate)

                                                                    startDate = new Date(new Date(startDate).getTime() - day * 24 * 60 * 60 * 1000).getTime()
                                                                    if (toolbarState.type_code === 1 || toolbarState.type_code === 2) {
                                                                        if (toolbarState.team === '' && toolbarState.order_status === '' && toolbarState.date === '') {
                                                                            return (<><tr>
                                                                                <th scope="row">{index + 1}</th>
                                                                                <th>
                                                                                    <div>{taskByID[list.task_id]?.subproject.label + '>' + taskByID[list.task_id]?.phase_plane.label + '>' + taskByID[list.task_id]?.work_packages.label}</div>
                                                                                </th>
                                                                                <th><div>{taskByID[list.task_id]?.name}</div></th>
                                                                                <th><div>{teamByID[taskByID[list.task_id]?.team.value]?.name}</div></th>
                                                                                <th><div>{materialById[list.material]?.material_name + ', ' + list.quantity + ', ' + supplierById[materialById[list.material]?.supplier]?.company_name}</div></th>
                                                                                <th><div>{new Date(taskByID[list.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[list.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[list.task_id]?.startDate).getFullYear()}</div></th>
                                                                                <th><div>{materialById[list.material]?.lead_time}</div></th>
                                                                                <th><div>{new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear()}</div></th>
                                                                                <th><div>{list.order_status ? new Date(list.ordered_time).getDate() + '/' + parseInt(new Date(list.ordered_time).getMonth() + 1) + '/' + new Date(list.ordered_time).getFullYear() : 'Not yet Ordered'}</div></th>
                                                                                <th><div>Material</div></th>
                                                                                <th>{list.order_status ? 'Ordered' : <button className='btn btn-primary btn-sm' onClick={() => OrderMaterial(list)}>Order</button>}</th>
                                                                            </tr>
                                                                            </>)
                                                                        } else if (toolbarState.team === taskByID[list.task_id]?.team.value && toolbarState.order_status === '' && toolbarState.date === '') {
                                                                            return (<><tr>
                                                                                <th scope="row">{index + 1}</th>
                                                                                <th>
                                                                                    <div>{taskByID[list.task_id]?.subproject.label + '>' + taskByID[list.task_id]?.phase_plane.label + '>' + taskByID[list.task_id]?.work_packages.label}</div>
                                                                                </th>
                                                                                <th><div>{taskByID[list.task_id]?.name}</div></th>
                                                                                <th><div>{teamByID[taskByID[list.task_id]?.team.value]?.name}</div></th>
                                                                                <th><div>{materialById[list.material]?.material_name + ', ' + list.quantity + ', ' + supplierById[materialById[list.material]?.supplier]?.company_name}</div></th>
                                                                                <th><div>{new Date(taskByID[list.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[list.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[list.task_id]?.startDate).getFullYear()}</div></th>
                                                                                <th><div>{materialById[list.material]?.lead_time}</div></th>
                                                                                <th><div>{new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear()}</div></th>
                                                                                <th><div>{list.order_status ? new Date(list.ordered_time).getDate() + '/' + parseInt(new Date(list.ordered_time).getMonth() + 1) + '/' + new Date(list.ordered_time).getFullYear() : 'Not yet Ordered'}</div></th>
                                                                                <th><div>Material</div></th>
                                                                                <th>{list.order_status ? 'Ordered' : <button className='btn btn-primary btn-sm' onClick={() => OrderMaterial(list)}>Order</button>}</th>
                                                                            </tr>
                                                                            </>)
                                                                        } else if (toolbarState.team === taskByID[list.task_id]?.team.value && toolbarState.order_status === list.order_status && toolbarState.date === '') {
                                                                            return (<><tr>
                                                                                <th scope="row">{index + 1}</th>
                                                                                <th>
                                                                                    <div>{taskByID[list.task_id]?.subproject.label + '>' + taskByID[list.task_id]?.phase_plane.label + '>' + taskByID[list.task_id]?.work_packages.label}</div>
                                                                                </th>
                                                                                <th><div>{taskByID[list.task_id]?.name}</div></th>
                                                                                <th><div>{teamByID[taskByID[list.task_id]?.team.value]?.name}</div></th>
                                                                                <th><div>{materialById[list.material]?.material_name + ', ' + list.quantity + ', ' + supplierById[materialById[list.material]?.supplier]?.company_name}</div></th>
                                                                                <th><div>{new Date(taskByID[list.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[list.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[list.task_id]?.startDate).getFullYear()}</div></th>
                                                                                <th><div>{materialById[list.material]?.lead_time}</div></th>
                                                                                <th><div>{new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear()}</div></th>
                                                                                <th><div>{list.order_status ? new Date(list.ordered_time).getDate() + '/' + parseInt(new Date(list.ordered_time).getMonth() + 1) + '/' + new Date(list.ordered_time).getFullYear() : 'Not yet Ordered'}</div></th>
                                                                                <th><div>Material</div></th>
                                                                                <th>{list.order_status ? 'Ordered' : <button className='btn btn-primary btn-sm' onClick={() => OrderMaterial(list)}>Order</button>}</th>
                                                                            </tr>
                                                                            </>)
                                                                        } else if (toolbarState.team === '' && toolbarState.order_status === list.order_status && toolbarState.date === '') {
                                                                            return (<><tr>
                                                                                <th scope="row">{index + 1}</th>
                                                                                <th>
                                                                                    <div>{taskByID[list.task_id]?.subproject.label + '>' + taskByID[list.task_id]?.phase_plane.label + '>' + taskByID[list.task_id]?.work_packages.label}</div>
                                                                                </th>
                                                                                <th><div>{taskByID[list.task_id]?.name}</div></th>
                                                                                <th><div>{teamByID[taskByID[list.task_id]?.team.value]?.name}</div></th>
                                                                                <th><div>{materialById[list.material]?.material_name + ', ' + list.quantity + ', ' + supplierById[materialById[list.material]?.supplier]?.company_name}</div></th>
                                                                                <th><div>{new Date(taskByID[list.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[list.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[list.task_id]?.startDate).getFullYear()}</div></th>
                                                                                <th><div>{materialById[list.material]?.lead_time}</div></th>
                                                                                <th><div>{new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear()}</div></th>
                                                                                <th><div>{list.order_status ? new Date(list.ordered_time).getDate() + '/' + parseInt(new Date(list.ordered_time).getMonth() + 1) + '/' + new Date(list.ordered_time).getFullYear() : 'Not yet Ordered'}</div></th>
                                                                                <th><div>Material</div></th>
                                                                                <th>{list.order_status ? 'Ordered' : <button className='btn btn-primary btn-sm' onClick={() => OrderMaterial(list)}>Order</button>}</th>
                                                                            </tr>
                                                                            </>)
                                                                        } else if (toolbarState.team === '' && toolbarState.order_status === '' && new Date(toolbarState.date).getTime() === taskByID[list.task_id]?.startDate) {
                                                                            return (<><tr>
                                                                                <th scope="row">{index + 1}</th>
                                                                                <th>
                                                                                    <div>{taskByID[list.task_id]?.subproject.label + '>' + taskByID[list.task_id]?.phase_plane.label + '>' + taskByID[list.task_id]?.work_packages.label}</div>
                                                                                </th>
                                                                                <th><div>{taskByID[list.task_id]?.name}</div></th>
                                                                                <th><div>{teamByID[taskByID[list.task_id]?.team.value]?.name}</div></th>
                                                                                <th><div>{materialById[list.material]?.material_name + ', ' + list.quantity + ', ' + supplierById[materialById[list.material]?.supplier]?.company_name}</div></th>
                                                                                <th><div>{new Date(taskByID[list.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[list.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[list.task_id]?.startDate).getFullYear()}</div></th>
                                                                                <th><div>{materialById[list.material]?.lead_time}</div></th>
                                                                                <th><div>{new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear()}</div></th>
                                                                                <th><div>{list.order_status ? new Date(list.ordered_time).getDate() + '/' + parseInt(new Date(list.ordered_time).getMonth() + 1) + '/' + new Date(list.ordered_time).getFullYear() : 'Not yet Ordered'}</div></th>
                                                                                <th><div>Material</div></th>
                                                                                <th>{list.order_status ? 'Ordered' : <button className='btn btn-primary btn-sm' onClick={() => OrderMaterial(list)}>Order</button>}</th>
                                                                            </tr>
                                                                            </>)
                                                                        } else if (toolbarState.team === '' && toolbarState.order_status === list.order_status && new Date(toolbarState.date).getTime() === taskByID[list.task_id]?.startDate) {
                                                                            return (<><tr>
                                                                                <th scope="row">{index + 1}</th>
                                                                                <th>
                                                                                    <div>{taskByID[list.task_id]?.subproject.label + '>' + taskByID[list.task_id]?.phase_plane.label + '>' + taskByID[list.task_id]?.work_packages.label}</div>
                                                                                </th>
                                                                                <th><div>{taskByID[list.task_id]?.name}</div></th>
                                                                                <th><div>{teamByID[taskByID[list.task_id]?.team.value]?.name}</div></th>
                                                                                <th><div>{materialById[list.material]?.material_name + ', ' + list.quantity + ', ' + supplierById[materialById[list.material]?.supplier]?.company_name}</div></th>
                                                                                <th><div>{new Date(taskByID[list.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[list.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[list.task_id]?.startDate).getFullYear()}</div></th>
                                                                                <th><div>{materialById[list.material]?.lead_time}</div></th>
                                                                                <th><div>{new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear()}</div></th>
                                                                                <th><div>{list.order_status ? new Date(list.ordered_time).getDate() + '/' + parseInt(new Date(list.ordered_time).getMonth() + 1) + '/' + new Date(list.ordered_time).getFullYear() : 'Not yet Ordered'}</div></th>
                                                                                <th><div>Material</div></th>
                                                                                <th>{list.order_status ? 'Ordered' : <button className='btn btn-primary btn-sm' onClick={() => OrderMaterial(list)}>Order</button>}</th>
                                                                            </tr>
                                                                            </>)
                                                                        } else if (toolbarState.team === taskByID[list.task_id]?.team.value && toolbarState.order_status === list.order_status && new Date(toolbarState.date).getTime() === taskByID[list.task_id]?.startDate) {
                                                                            return (<><tr>
                                                                                <th scope="row">{index + 1}</th>
                                                                                <th>
                                                                                    <div>{taskByID[list.task_id]?.subproject.label + '>' + taskByID[list.task_id]?.phase_plane.label + '>' + taskByID[list.task_id]?.work_packages.label}</div>
                                                                                </th>
                                                                                <th><div>{taskByID[list.task_id]?.name}</div></th>
                                                                                <th><div>{teamByID[taskByID[list.task_id]?.team.value]?.name}</div></th>
                                                                                <th><div>{materialById[list.material]?.material_name + ', ' + list.quantity + ', ' + supplierById[materialById[list.material]?.supplier]?.company_name}</div></th>
                                                                                <th><div>{new Date(taskByID[list.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[list.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[list.task_id]?.startDate).getFullYear()}</div></th>
                                                                                <th><div>{materialById[list.material]?.lead_time}</div></th>
                                                                                <th><div>{new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear()}</div></th>
                                                                                <th><div>{list.order_status ? new Date(list.ordered_time).getDate() + '/' + parseInt(new Date(list.ordered_time).getMonth() + 1) + '/' + new Date(list.ordered_time).getFullYear() : 'Not yet Ordered'}</div></th>
                                                                                <th><div>Material</div></th>
                                                                                <th>{list.order_status ? 'Ordered' : <button className='btn btn-primary btn-sm' onClick={() => OrderMaterial(list)}>Order</button>}</th>
                                                                            </tr>
                                                                            </>)
                                                                        } else if (toolbarState.team === taskByID[list.task_id]?.team.value && toolbarState.order_status === '' && new Date(toolbarState.date).getTime() === taskByID[list.task_id]?.startDate) {
                                                                            return (<><tr>
                                                                                <th scope="row">{index + 1}</th>
                                                                                <th>
                                                                                    <div>{taskByID[list.task_id]?.subproject.label + '>' + taskByID[list.task_id]?.phase_plane.label + '>' + taskByID[list.task_id]?.work_packages.label}</div>
                                                                                </th>
                                                                                <th><div>{taskByID[list.task_id]?.name}</div></th>
                                                                                <th><div>{teamByID[taskByID[list.task_id]?.team.value]?.name}</div></th>
                                                                                <th><div>{materialById[list.material]?.material_name + ', ' + list.quantity + ', ' + supplierById[materialById[list.material]?.supplier]?.company_name}</div></th>
                                                                                <th><div>{new Date(taskByID[list.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[list.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[list.task_id]?.startDate).getFullYear()}</div></th>
                                                                                <th><div>{materialById[list.material]?.lead_time}</div></th>
                                                                                <th><div>{new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear()}</div></th>
                                                                                <th><div>{list.order_status ? new Date(list.ordered_time).getDate() + '/' + parseInt(new Date(list.ordered_time).getMonth() + 1) + '/' + new Date(list.ordered_time).getFullYear() : 'Not yet Ordered'}</div></th>
                                                                                <th><div>Material</div></th>
                                                                                <th>{list.order_status ? 'Ordered' : <button className='btn btn-primary btn-sm' onClick={() => OrderMaterial(list)}>Order</button>}</th>
                                                                            </tr>
                                                                            </>)
                                                                        }
                                                                    }


                                                                })}
                                                                {taskequipmentList && taskequipmentList.map((list, key) => {
                                                                    var startDate = new Date(new Date(taskByID[list.task_id]?.startDate).getTime()).getTime()
                                                                    var endDate = taskByID[list.task_id]?.startDate
                                                                    let day = getBusinessDatesCount(startDate, endDate)
                                                                    startDate = new Date(new Date(startDate).getTime() - day * 24 * 60 * 60 * 1000).getTime()
                                                                    if (toolbarState.type_code === 1 || toolbarState.type_code === 3) {
                                                                        if (toolbarState.team === '' && toolbarState.order_status === '' && toolbarState.date === '') {
                                                                            return (<>
                                                                                <tr>
                                                                                    <th scope="row">{count + key + 1}</th>
                                                                                    <th>
                                                                                        <div>{taskByID[list.task_id]?.subproject.label + '>' + taskByID[list.task_id]?.phase_plane.label + '>' + taskByID[list.task_id]?.work_packages.label}</div>
                                                                                    </th>
                                                                                    <th><div>{taskByID[list.task_id]?.name}</div></th>
                                                                                    <th><div>{teamByID[taskByID[list.task_id]?.team.value]?.name}</div></th>
                                                                                    <th><div>{equipmentByID[list.equipment]?.equipment + ',' + supplierById[equipmentByID[list.equipment]?.supplier]?.company_name}</div></th>
                                                                                    <th><div>{new Date(taskByID[list.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[list.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[list.task_id]?.startDate).getFullYear()}</div></th>
                                                                                    <th><div></div></th>
                                                                                    <th><div>{new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear()}</div></th>
                                                                                    <th><div>{list.order_status ? new Date(list.ordered_time).getDate() + '/' + parseInt(new Date(list.ordered_time).getMonth() + 1) + '/' + new Date(list.ordered_time).getFullYear() : 'Not yet Ordered'}</div></th>
                                                                                    <th><div>Equipment</div></th>
                                                                                    <th>{list.order_status ? 'Ordered' : <button className='btn btn-primary btn-sm' onClick={() => OrderEquipment(list)} >Order</button>}</th>
                                                                                </tr>
                                                                            </>)

                                                                        } else if (toolbarState.team === taskByID[list.task_id]?.team.value && toolbarState.order_status === '' && toolbarState.date === '') {
                                                                            return (<>
                                                                                <tr>
                                                                                    <th scope="row">{count + key + 1}</th>
                                                                                    <th>
                                                                                        <div>{taskByID[list.task_id]?.subproject.label + '>' + taskByID[list.task_id]?.phase_plane.label + '>' + taskByID[list.task_id]?.work_packages.label}</div>
                                                                                    </th>
                                                                                    <th><div>{taskByID[list.task_id]?.name}</div></th>
                                                                                    <th><div>{teamByID[taskByID[list.task_id]?.team.value]?.name}</div></th>
                                                                                    <th><div>{equipmentByID[list.equipment]?.equipment + ',' + supplierById[equipmentByID[list.equipment]?.supplier]?.company_name}</div></th>
                                                                                    <th><div>{new Date(taskByID[list.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[list.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[list.task_id]?.startDate).getFullYear()}</div></th>
                                                                                    <th><div></div></th>
                                                                                    <th><div>{new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear()}</div></th>
                                                                                    <th><div>{list.order_status ? new Date(list.ordered_time).getDate() + '/' + parseInt(new Date(list.ordered_time).getMonth() + 1) + '/' + new Date(list.ordered_time).getFullYear() : 'Not yet Ordered'}</div></th>
                                                                                    <th><div>Equipment</div></th>
                                                                                    <th>{list.order_status ? 'Ordered' : <button className='btn btn-primary btn-sm' onClick={() => OrderEquipment(list)} >Order</button>}</th>
                                                                                </tr>
                                                                            </>)
                                                                        } else if (toolbarState.team === taskByID[list.task_id]?.team.value && toolbarState.order_status === list.order_status && toolbarState.date === '') {
                                                                            return (<>
                                                                                <tr>
                                                                                    <th scope="row">{count + key + 1}</th>
                                                                                    <th>
                                                                                        <div>{taskByID[list.task_id]?.subproject.label + '>' + taskByID[list.task_id]?.phase_plane.label + '>' + taskByID[list.task_id]?.work_packages.label}</div>
                                                                                    </th>
                                                                                    <th><div>{taskByID[list.task_id]?.name}</div></th>
                                                                                    <th><div>{teamByID[taskByID[list.task_id]?.team.value]?.name}</div></th>
                                                                                    <th><div>{equipmentByID[list.equipment]?.equipment + ',' + supplierById[equipmentByID[list.equipment]?.supplier]?.company_name}</div></th>
                                                                                    <th><div>{new Date(taskByID[list.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[list.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[list.task_id]?.startDate).getFullYear()}</div></th>
                                                                                    <th><div></div></th>
                                                                                    <th><div>{new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear()}</div></th>
                                                                                    <th><div>{list.order_status ? new Date(list.ordered_time).getDate() + '/' + parseInt(new Date(list.ordered_time).getMonth() + 1) + '/' + new Date(list.ordered_time).getFullYear() : 'Not yet Ordered'}</div></th>
                                                                                    <th><div>Equipment</div></th>
                                                                                    <th>{list.order_status ? 'Ordered' : <button className='btn btn-primary btn-sm' onClick={() => OrderEquipment(list)} >Order</button>}</th>
                                                                                </tr>
                                                                            </>)
                                                                        } else if (toolbarState.team === '' && toolbarState.order_status === list.order_status && toolbarState.date === '') {
                                                                            return (<>
                                                                                <tr>
                                                                                    <th scope="row">{count + key + 1}</th>
                                                                                    <th>
                                                                                        <div>{taskByID[list.task_id]?.subproject.label + '>' + taskByID[list.task_id]?.phase_plane.label + '>' + taskByID[list.task_id]?.work_packages.label}</div>
                                                                                    </th>
                                                                                    <th><div>{taskByID[list.task_id]?.name}</div></th>
                                                                                    <th><div>{teamByID[taskByID[list.task_id]?.team.value]?.name}</div></th>
                                                                                    <th><div>{equipmentByID[list.equipment]?.equipment + ',' + supplierById[equipmentByID[list.equipment]?.supplier]?.company_name}</div></th>
                                                                                    <th><div>{new Date(taskByID[list.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[list.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[list.task_id]?.startDate).getFullYear()}</div></th>
                                                                                    <th><div></div></th>
                                                                                    <th><div>{new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear()}</div></th>
                                                                                    <th><div>{list.order_status ? new Date(list.ordered_time).getDate() + '/' + parseInt(new Date(list.ordered_time).getMonth() + 1) + '/' + new Date(list.ordered_time).getFullYear() : 'Not yet Ordered'}</div></th>
                                                                                    <th><div>Equipment</div></th>
                                                                                    <th>{list.order_status ? 'Ordered' : <button className='btn btn-primary btn-sm' onClick={() => OrderEquipment(list)} >Order</button>}</th>
                                                                                </tr>
                                                                            </>)
                                                                        } else if (toolbarState.team === '' && toolbarState.order_status === '' && new Date(toolbarState.date).getTime() === taskByID[list.task_id]?.startDate) {
                                                                            return (<>
                                                                                <tr>
                                                                                    <th scope="row">{count + key + 1}</th>
                                                                                    <th>
                                                                                        <div>{taskByID[list.task_id]?.subproject.label + '>' + taskByID[list.task_id]?.phase_plane.label + '>' + taskByID[list.task_id]?.work_packages.label}</div>
                                                                                    </th>
                                                                                    <th><div>{taskByID[list.task_id]?.name}</div></th>
                                                                                    <th><div>{teamByID[taskByID[list.task_id]?.team.value]?.name}</div></th>
                                                                                    <th><div>{equipmentByID[list.equipment]?.equipment + ',' + supplierById[equipmentByID[list.equipment]?.supplier]?.company_name}</div></th>
                                                                                    <th><div>{new Date(taskByID[list.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[list.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[list.task_id]?.startDate).getFullYear()}</div></th>
                                                                                    <th><div></div></th>
                                                                                    <th><div>{new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear()}</div></th>
                                                                                    <th><div>{list.order_status ? new Date(list.ordered_time).getDate() + '/' + parseInt(new Date(list.ordered_time).getMonth() + 1) + '/' + new Date(list.ordered_time).getFullYear() : 'Not yet Ordered'}</div></th>
                                                                                    <th><div>Equipment</div></th>
                                                                                    <th>{list.order_status ? 'Ordered' : <button className='btn btn-primary btn-sm' onClick={() => OrderEquipment(list)} >Order</button>}</th>
                                                                                </tr>
                                                                            </>)
                                                                        } else if (toolbarState.team === '' && toolbarState.order_status === list.order_status && new Date(toolbarState.date).getTime() === taskByID[list.task_id]?.startDate) {
                                                                            return (<>
                                                                                <tr>
                                                                                    <th scope="row">{count + key + 1}</th>
                                                                                    <th>
                                                                                        <div>{taskByID[list.task_id]?.subproject.label + '>' + taskByID[list.task_id]?.phase_plane.label + '>' + taskByID[list.task_id]?.work_packages.label}</div>
                                                                                    </th>
                                                                                    <th><div>{taskByID[list.task_id]?.name}</div></th>
                                                                                    <th><div>{teamByID[taskByID[list.task_id]?.team.value]?.name}</div></th>
                                                                                    <th><div>{equipmentByID[list.equipment]?.equipment + ',' + supplierById[equipmentByID[list.equipment]?.supplier]?.company_name}</div></th>
                                                                                    <th><div>{new Date(taskByID[list.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[list.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[list.task_id]?.startDate).getFullYear()}</div></th>
                                                                                    <th><div></div></th>
                                                                                    <th><div>{new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear()}</div></th>
                                                                                    <th><div>{list.order_status ? new Date(list.ordered_time).getDate() + '/' + parseInt(new Date(list.ordered_time).getMonth() + 1) + '/' + new Date(list.ordered_time).getFullYear() : 'Not yet Ordered'}</div></th>
                                                                                    <th><div>Equipment</div></th>
                                                                                    <th>{list.order_status ? 'Ordered' : <button className='btn btn-primary btn-sm' onClick={() => OrderEquipment(list)}>Order</button>}</th>
                                                                                </tr>
                                                                            </>)
                                                                        } else if (toolbarState.team === taskByID[list.task_id]?.team.value && toolbarState.order_status === list.order_status && new Date(toolbarState.date).getTime() === taskByID[list.task_id]?.startDate) {
                                                                            return (<>
                                                                                <tr>
                                                                                    <th scope="row">{count + key + 1}</th>
                                                                                    <th>
                                                                                        <div>{taskByID[list.task_id]?.subproject.label + '>' + taskByID[list.task_id]?.phase_plane.label + '>' + taskByID[list.task_id]?.work_packages.label}</div>
                                                                                    </th>
                                                                                    <th><div>{taskByID[list.task_id]?.name}</div></th>
                                                                                    <th><div>{teamByID[taskByID[list.task_id]?.team.value]?.name}</div></th>
                                                                                    <th><div>{equipmentByID[list.equipment]?.equipment + ',' + supplierById[equipmentByID[list.equipment]?.supplier]?.company_name}</div></th>
                                                                                    <th><div>{new Date(taskByID[list.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[list.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[list.task_id]?.startDate).getFullYear()}</div></th>
                                                                                    <th><div></div></th>
                                                                                    <th><div>{new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear()}</div></th>
                                                                                    <th><div>{list.order_status ? new Date(list.ordered_time).getDate() + '/' + parseInt(new Date(list.ordered_time).getMonth() + 1) + '/' + new Date(list.ordered_time).getFullYear() : 'Not yet Ordered'}</div></th>
                                                                                    <th><div>Equipment</div></th>
                                                                                    <th>{list.order_status ? 'Ordered' : <button className='btn btn-primary btn-sm' onClick={() => OrderEquipment(list)} >Order</button>}</th>
                                                                                </tr>
                                                                            </>)
                                                                        } else if (toolbarState.team === taskByID[list.task_id]?.team.value && toolbarState.order_status === '' && new Date(toolbarState.date).getTime() === taskByID[list.task_id]?.startDate) {
                                                                            return (<>
                                                                                <tr>
                                                                                    <th scope="row">{count + key + 1}</th>
                                                                                    <th>
                                                                                        <div>{taskByID[list.task_id]?.subproject.label + '>' + taskByID[list.task_id]?.phase_plane.label + '>' + taskByID[list.task_id]?.work_packages.label}</div>
                                                                                    </th>
                                                                                    <th><div>{taskByID[list.task_id]?.name}</div></th>
                                                                                    <th><div>{teamByID[taskByID[list.task_id]?.team.value]?.name}</div></th>
                                                                                    <th><div>{equipmentByID[list.equipment]?.equipment + ',' + supplierById[equipmentByID[list.equipment]?.supplier]?.company_name}</div></th>
                                                                                    <th><div>{new Date(taskByID[list.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[list.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[list.task_id]?.startDate).getFullYear()}</div></th>
                                                                                    <th><div></div></th>
                                                                                    <th><div>{new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear()}</div></th>
                                                                                    <th><div>{list.order_status ? new Date(list.ordered_time).getDate() + '/' + parseInt(new Date(list.ordered_time).getMonth() + 1) + '/' + new Date(list.ordered_time).getFullYear() : 'Not yet Ordered'}</div></th>
                                                                                    <th><div>Equipment</div></th>
                                                                                    <th>{list.order_status ? 'Ordered' : <button className='btn btn-primary btn-sm' onClick={() => OrderEquipment(list)} >Order</button>}</th>
                                                                                </tr>
                                                                            </>)
                                                                        }
                                                                    }


                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    )
}

export default MaterialEquipmentLogs