import axios from 'axios'
const config = require('../config')


const api = axios.create({
    baseURL: config.api_url + '/api'
});

export const userVerifyToken = payout => api.get('/me', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
});

/* Profile Api Routes */
export const userlogin = payload => api.post('/userlogin', payload)
export const forgetUser = payload => api.post('/forget-user', payload)
export const resetPassword = payload => api.post('/reset-password', payload)
export const verifyEmail = payload => api.post('/verify-email', payload)
export const changePassword = payload => api.post('/update-password', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateSystemPassword = payload => api.post('/update-sys-info', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const checkSystemInfo = payload => api.post('/check-system-info', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* ReasonCodes Api Routes */
export const reasonCodes = payload => api.get('/fetch-reason-codes', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addReasonCodes = payload => api.post('/add-reason-codes', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateReasonCodes = payload => api.post('/update-reason-codes', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const deleteReasonCodes = payload => api.post('/delete-reason-codes', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* Attributes Api Routes */
export const getAttribute = payload => api.get('/attributes', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addAttribute = payload => api.post('/add-attribute', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateAttribute = payload => api.post('/edit-attribute', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const deleteAttribute = payload => api.post('/delete-attribute', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* Team Api Routes */
export const getTeam = payload => api.get('/get-team', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addTeam = payload => api.post('/add-team', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const deleteTeam = payload => api.post('/delete-team', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateTeam = payload => api.post('/edit-team', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* Member Api Routes */
export const getMember = payload => api.get('/get-member', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addMember = payload => api.post('/add-member', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const deleteMember = payload => api.post('/delete-member', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateMember = payload => api.post('/edit-member', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* SuAdmin Api Routes */
export const getSubAdmin = payload => api.get('/get-sub-admin', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
});
export const addSubAdmin = payload => api.post('/add-sub-admin', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
});
export const updateSubAdmin = payload => api.post('/edit-sub-admin', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
});
export const convertToSubAdmin = payload => api.post('/update-sub-admin', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
});

/* Association Routes */
export const getAssociation = payload => api.get('/get-association', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addAssociation = payload => api.post('/add-association', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateAssociation = payload => api.post('/update-association', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const deleteAssociation = payload => api.post('/delete-association', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* Project Setting Routes */
export const getCalendar = payload => api.get('/get-calender', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateCalender = payload => api.post('/update-calender', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* Projects Routes */
export const getProject = payload => api.post('/get-projects', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addProject = payload => api.post('/add-project', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateProject = payload => api.post('/update-project', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const lockProject = payload => api.post('/lock-project', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* Plans Routes*/
export const getPlan = payload => api.post('/get-plans', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addPlan = payload => api.post('/add-plan', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updatePlan = payload => api.post('/update-plan', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const lockPlan = payload => api.post('/lock-plan', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const getAllPlan = payload => api.get('/get-all-plans', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/*Cost Attributes Api Routes */
export const getCostAttribute = payload => api.get('/cost-attributes', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addCostAttribute = payload => api.post('/add-cost-attribute', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateCostAttribute = payload => api.post('/edit-cost-attribute', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const deleteCostAttribute = payload => api.post('/delete-cost-attribute', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/*Cost Codes Api Routes */
export const getCostCodes = payload => api.get('/cost-codes', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addCostCode = payload => api.post('/add-cost-code', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateCostCode = payload => api.post('/edit-cost-code', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const deleteCostCode = payload => api.post('/delete-cost-code', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/*Cost Component Api Routes */
export const getCostComponents = payload => api.get('/cost-components', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addCostComponent = payload => api.post('/add-cost-component', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateCostComponent = payload => api.post('/edit-cost-component', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const deleteCostComponent = payload => api.post('/delete-cost-component', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* Cost Track Api Routes */
export const getCostTrackMp = payload => api.get('/cost-track-mp', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addCostTrackMp = payload => api.post('/add-cost-track-mp', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const editCostTrackPrice = payload => api.post('/edit-cost-track-price', payload)

/* Task Api Routes */
export const getTask = payload => api.get('/get-task', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addTask = payload => api.post('/add-task', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateTask = payload => api.post('/update-task', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateTaskDrag = payload => api.post('/update-task-drag', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const deleteTask = payload => api.post('/delete-task', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const createLink = payload => api.post('/create-link', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const deleteLink = payload => api.post('/delete-link', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const taskfilter = payload => api.post('/get-task-filter', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const resizeTask = payload => api.post('/get-task-resize', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const postionTask = payload => api.post('/set-task-postion', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const bulkTaskDrag = payload => api.post('/update-bulk-task-drag', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const getTaskLog = payload => api.post('/get_task_log', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* Commitment APi Routes */
export const commitmentTask = payload => api.post('/get-commitment-plans', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addcommitmentplan = payload => api.post('/add-commitment-plan', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const closecommitmentplan = payload => api.post('/close-commitment-plan', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const closecommitmentplannotcomplete = payload => api.post('/close-commitment-plan-not-complete', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateCommitmentTask = payload => api.post('/update-commitment-task', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateCommitTask = payload => api.post('/update-commit-task', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* Route for Analytics And Reports */

export const getAnalytice = payload => api.post('/get-analytics', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const getCommitmentReport = payload => api.post('/get-commitment-report', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* Route for Notes */

export const getNotes = payload => api.post('/get_notes', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addNotes = payload => api.post('/add_notes', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateNotes = payload => api.post('/update_notes', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/*  */
export const taskVarianceFilter = payload => api.post('/get-variance-filter', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* Apis for Constraints Log */
export const getConstraint = payload => api.post('/get-constraint-log', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addConstraint = payload => api.post('/add-constraint-log', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateConstraintCheckedList = payload => api.post('/update-checked-list', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateConstraintsPosition = payload => api.post('/update-constraints-position', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

export const updateConstraint = payload => api.post('/update-constraints', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addConstraintComment = payload => api.post('/add-constraints-comment', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

export const addBoards = payload => api.post('/add-boards', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const getBoards = payload => api.get('/get-boards', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

export const addConstraintActions = payload => api.post('/add-constraints-actions', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* Apis For Material Manager Suppliers */
export const getSuppliers = payload => api.get('/get-supplier', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addSuppliers = payload => api.post('/add-supplier', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateSupplier = payload => api.post('/update-supplier', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const deleteSupplier = payload => api.post('/delete-supplier', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/*Material Attributes Api Routes */
export const getmaterialAttribute = payload => api.get('/material-attributes', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addmaterialAttribute = payload => api.post('/add-material-attribute', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updatematerialAttribute = payload => api.post('/edit-material-attribute', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const deletematerialAttribute = payload => api.post('/delete-material-attribute', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* Material Equipment Api Routes */
export const getMaterialEquipment = payload => api.get('/get-material-equipment', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addMaterialEquipment = payload => api.post('/add-material-equipment', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateMaterialEquiment = payload => api.post('/edit-material-equipment', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const deleteMaterialEquiment = payload => api.post('/delete-material-equipment', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
/* Material  Api Routes */
export const getMaterial = payload => api.get('/get-material', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const getMaterialBySuppliers = payload => api.post('/get-material-by-supplier', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addMaterial = payload => api.post('/add-material', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateMaterial = payload => api.post('/edit-material', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const deleteMaterial = payload => api.post('/delete-material', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* Task Material Api Routes */
export const getTaskMaterial = payload => api.post('/get-task-material', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const getTaskMaterialByFilter = payload => api.post('/get-task-materials-by-filter', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addTaskMaterial = payload => api.post('/add-task-material', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateTaskMaterial = payload => api.post('/edit-task-material', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const deleteTaskMaterial = payload => api.post('/delete-task-material', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const getAllTaskMaterial = payload => api.get('/get-all-task-material', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const placeTaskMaterialOreded = payload => api.post('/ordered-task-material', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* Task Equipment APi Routes */
export const getAllTaskEquipment = payload => api.get('/get-all-task-equipment', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const getTaskEquipment = payload => api.post('/get-task-equipment', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addTaskEquipment = payload => api.post('/add-task-equipment', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateTaskEquipment = payload => api.post('/edit-task-equipment', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const deleteTaskEquipment = payload => api.post('/delete-task-equipment', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const placeTaskEquipmentOreded = payload => api.post('/ordered-task-equipment', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const getTaskEquipmentByFilter = payload => api.post('/get-task-equipment-by-filter', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* Process Mapper Api Routes */
export const getProcess = (payload) => api.post("/get-process-map", payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
});
export const addProcess = (payload) => api.post("/add-process-map", payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
});
export const updateProcess = (payload) => api.post("/update-process-map", payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
});
export const deleteProcess = (payload) => api.post("/delete-process-map", payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
});
export const uploadFiles = (payload) => api.post("/upload-process", payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
});
export const getUploadFiles = (payload) => api.post("/get-uploaded-process", payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
});

/* Login Image And Other */
export const getSetting = (payload) => api.get('/get-setting', {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
});
export const addSetting = (payload) => api.post('/add-project-setting', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const addLogingImage = (payload) => api.post('/add-loging-image', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const updateLogingImage = (payload) => api.post('/update-loging-image', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})
export const uploadImportCSV = (payload) => api.post('/import-csv', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

export const uploadMembereImportCSV = (payload) => api.post('/import-member-csv', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})


/* Complete Milestone */

export const closeMilestons = (payload) => api.get('/update_milestone/' + payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})

/* Comlete Not Planned task */

export const completeNotPlannedTask = (payload) => api.post('/update-complete-not-planned-task', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }

})

/* User Login log Api Routes */
export const userLoginLog = payload => api.post('/user-login-log', payload, {
    headers: {
        'x-access-token': localStorage.getItem('verfication-token') ? localStorage.getItem('verfication-token') : ''
    }
})



const Apis = {
    userVerifyToken,
    userlogin,
    forgetUser,
    resetPassword,
    verifyEmail,
    changePassword,
    reasonCodes,
    addReasonCodes,
    updateReasonCodes,
    deleteReasonCodes,
    getAttribute,
    addAttribute,
    deleteAttribute,
    updateAttribute,
    updateSystemPassword,
    checkSystemInfo,
    getTeam,
    addTeam,
    deleteTeam,
    updateTeam,
    getAssociation,
    addAssociation,
    updateAssociation,
    deleteAssociation,
    getMember,
    addMember,
    deleteMember,
    updateMember,
    getCalendar,
    updateCalender,
    getProject,
    addProject,
    updateProject,
    lockProject,
    getAllPlan,
    getPlan,
    addPlan,
    updatePlan,
    lockPlan,
    getCostAttribute,
    addCostAttribute,
    updateCostAttribute,
    deleteCostAttribute,
    getCostCodes,
    addCostCode,
    updateCostCode,
    deleteCostCode,
    getCostComponents,
    addCostComponent,
    updateCostComponent,
    deleteCostComponent,
    getCostTrackMp,
    addCostTrackMp,
    editCostTrackPrice,
    getTask,
    addTask,
    updateTask,
    updateTaskDrag,
    deleteTask,
    createLink,
    taskfilter,
    commitmentTask,
    addcommitmentplan,
    closecommitmentplan,
    deleteLink,
    resizeTask,
    postionTask,
    bulkTaskDrag,
    closecommitmentplannotcomplete,
    getAnalytice,
    updateCommitmentTask,
    updateCommitTask,
    getCommitmentReport,
    getTaskLog,
    getNotes,
    addNotes,
    updateNotes,
    taskVarianceFilter,
    getConstraint,
    addConstraint,
    updateConstraintCheckedList,
    updateConstraintsPosition,
    updateConstraint,
    addConstraintComment,
    addBoards,
    getBoards,
    addConstraintActions,
    getSuppliers,
    addSuppliers,
    updateSupplier,
    deleteSupplier,
    getmaterialAttribute,
    addmaterialAttribute,
    updatematerialAttribute,
    deletematerialAttribute,
    getMaterialEquipment,
    addMaterialEquipment,
    updateMaterialEquiment,
    deleteMaterialEquiment,
    getMaterial,
    addMaterial,
    updateMaterial,
    deleteMaterial,
    getTaskMaterial,
    addTaskMaterial,
    updateTaskMaterial,
    deleteTaskMaterial,
    getTaskEquipment,
    addTaskEquipment,
    updateTaskEquipment,
    deleteTaskEquipment,
    getAllTaskMaterial,
    placeTaskMaterialOreded,
    getAllTaskEquipment,
    placeTaskEquipmentOreded,
    getTaskMaterialByFilter,
    getMaterialBySuppliers,
    getTaskEquipmentByFilter,
    getProcess,
    addProcess,
    updateProcess,
    deleteProcess,
    uploadFiles,
    getUploadFiles,
    getSetting,
    updateLogingImage,
    uploadImportCSV,
    addLogingImage,
    addSetting,
    getSubAdmin,
    addSubAdmin,
    updateSubAdmin,
    closeMilestons,
    convertToSubAdmin,
    completeNotPlannedTask,
    uploadMembereImportCSV,
    userLoginLog
}

export default Apis