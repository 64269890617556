import React from 'react'
import swal from 'sweetalert'
import Apis from '../../../../api'
import keyBy from 'lodash/keyBy'

const MaterrialList = ({ refresh, handleEdit, suppliers, attribute }) => {
    const [materialList, setMaterialsList] = React.useState([])
    const [refreshList, setRefreshList] = React.useState(true)
    const supplierById = keyBy(suppliers, '_id')
    const attributeById = keyBy(attribute, '_id')
    React.useEffect(() => {
        Apis.getMaterial().then((res) => {
            setMaterialsList(res.data.data.materials)
        }).catch((error) => {
            console.log(error)
        })

    }, [refresh, refreshList])

    const handleDelete = (id, status) => {
        let title = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
        } else {
            title = "Are you sure want to restore?"
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const raw = {
                    id: id
                }
                Apis.deleteMaterial(raw).then((res) => {
                    swal({
                        title: res.data.message,
                        icon: "success"
                    }).then((result) => {
                        setRefreshList(!refreshList)
                    })
                }).catch((error) => {
                    console.log(error)
                })
            }
        })
    }
    return (
        <>
            <div className="table-responsive">
                <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Company/Supplier</th>
                            <th scope="col">Material Name</th>
                            <th scope="col">Lead Time (Days)</th>
                            <th scope="col">UoM (Unit of Measure)</th>
                            <th scope="col">Description</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {materialList && materialList.map((list, index) => {
                            if (list.status) {
                                return (<>
                                    <tr>
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                            <div>{supplierById[list.supplier]?.company_name}</div>
                                        </td>
                                        <td>
                                            <div>{list.material_name}</div>
                                        </td>
                                        <td>
                                            <div>{list.lead_time}</div>
                                        </td>
                                        <td >
                                            <div>{attributeById[list.uom]?.tag_name}</div>
                                        </td>
                                        <td style={{ 'white-space': 'break-spaces' }}>
                                            <div>{list.description}</div>
                                        </td>
                                        <td>
                                            <div class="d-flex addremove_btn">
                                                <a className="btn btn-primary btn-sm" onClick={() => handleEdit(list)}><i className="fas fa-pencil-alt"></i></a>
                                                <a className="btn btn-primary btn-sm" onClick={() => handleDelete(list._id, 0)}><i className="fas fa-trash-alt"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </>)
                            } else {
                                return (<>
                                    <tr>
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                            <strike>{supplierById[list.supplier]?.company_name}</strike>
                                        </td>
                                        <td>
                                            <strike>{list.material_name}</strike>
                                        </td>
                                        <td>
                                            <strike>{list.lead_time}</strike>
                                        </td>
                                        <td>
                                            <strike>{attributeById[list.uom]?.tag_name}</strike>
                                        </td>
                                        <td style={{ 'white-space': 'break-spaces' }}>
                                            <strike>{list.description}</strike>
                                        </td>
                                        <td>
                                            <div class="d-flex addremove_btn">
                                                <a className="btn btn-primary btn-sm" onClick={() => handleDelete(list._id, 1)}><i className="fas fa-trash-restore"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </>)
                            }

                        })}

                    </tbody>
                </table>
            </div>
        </>
    )
}

export default MaterrialList