import React from 'react'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Footer from '../Layout/Footer'
import Alert from '../../../helpers/Alert'
import { components } from "react-select"
import { default as ReactSelect } from "react-select"
import Modal from 'react-bootstrap/Modal'
import Apis from '../../../api'
import swal from 'sweetalert'
import WorkListTable from './components/WorkListTable'
import SubProjectTable from './components/SubProjectTable'
import PhasePlanTable from './components/PhasePlanTable'

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    )
}

function Projects() {

    const [title, setTitle] = React.useState('Work Structure - Work Package Tags')
    const [subtitle, setSubTitle] = React.useState('')
    const [attributes, setAttribute] = React.useState([])
    const [refresh, setRefresh] = React.useState(false);
    const [attribute_popup, setAtteributePopup] = React.useState(false);
    const [confirmPassword, setConfirmPassword] = React.useState(false);
    const [newSubProject, setNewSubProject] = React.useState(false);
    const [newSubPlan, setNewSubPlan] = React.useState(false);
    const [planList, setPlanList] = React.useState([]);
    const user = JSON.parse(localStorage.getItem('user-info'))
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })

    const [projectList, setProjectList] = React.useState([]);
    const [work_package_list, setWorkPackage] = React.useState([])

    const [values, setValues] = React.useState({
        user_id: user.id,
        user_name: user.name,
        id: '',
        plan_id: '',
        key: 'system_password',
        system_password_status: false,
        system_password: '',
        project_name: '',
        project_description: '',
        edit_card: 0,
        project_lock: 0,
        project_lock_status: '',
        plan_lock: 0,
        plan_lock_status: '',
        plan_phase: 0,
        project_id: '',
        plan_name: '',
        plan_description: '',
        work_packages: [],
        edit_plan_card: 0,
    })

    const [attribute_values, setAttributeValues] = React.useState({
        work_tag: '',
        work_handle: '',
        id: ''
    })

    React.useEffect(() => {
        Apis.getAttribute().then((res) => {
            setAttribute(res.data.data)
        }).catch((error) => {
            console.log(error.response)
        })
        const user = JSON.parse(localStorage.getItem('user-info'))
        const raw = {
            'user_id': user.id
        }
        Apis.getProject(raw).then((res) => {
            setProjectList(res.data.data)
            window.localStorage.setItem('project-info', JSON.stringify(res.data.data))
        }).catch((error) => {
            console.log(error.response.data)
        })
    }, [refresh])
    React.useEffect(() => {
        let demo
        let list = []
        if (attributes.length > 0) {
            attributes.projectattribute.map((elm) => {
                if (elm.attribute_name === "Work Package") {
                    demo = {
                        value: elm._id,
                        label: elm.tag_name
                    }
                    list.push(demo)
                }
                return list
            })
            setWorkPackage(list)
        }
    }, [attributes])

    React.useEffect(() => {
        setError({
            ...error,
            error: '',
            color: '',
            open: false
        })
    }, [title])

    React.useEffect(() => {
        if (attribute_popup === false) {
            setAttributeValues({
                work_tag: '',
                work_handle: '',
                id: ''
            })
        }
    }, [attribute_popup])

    React.useEffect(() => {
        if (newSubProject === false) {
            setValues({
                ...values,
                id: '',
                project_name: '',
                project_description: '',
                edit_card: 0
            })
        }
    }, [newSubProject])
    React.useEffect(() => {
        if (newSubPlan === false) {
            setValues({
                ...values,
                plan_id: '',
                plan_name: '',
                plan_description: '',
                work_packages: '',
                edit_plan_card: 0,
            })

        }
    }, [newSubPlan])

    const handlePlanSubmit = (formdata) => {
        setError({
            ...error,
            open: false
        })

        if (formdata.plan_name === '') {
            setError({
                ...error,
                error: 'Plan name is required',
                color: 'error',
                open: true
            })
        } else {
            if (formdata.plan_id !== '') {

                const raw = {
                    'plan_id': formdata.plan_id,
                    'name': formdata.plan_name,
                    'description': formdata.plan_description,
                    'packages': formdata.work_packages
                }

                Apis.updatePlan(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        plan_name: '',
                        plan_description: '',
                        work_packages: [],
                        edit_plan_card: 0,
                        plan_id: ''
                    })
                    const raw = {
                        'user_id': user.id,
                        'project_id': formdata.project_id
                    }
                    Apis.getPlan(raw).then((res) => {
                        setPlanList(res.data.data)
                        setNewSubPlan(!newSubPlan)
                    }).catch((error) => {
                        console.log(error.response.data)
                    })

                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            } else {
                const raw = {
                    'project_id': formdata.project_id,
                    'name': formdata.plan_name,
                    'description': formdata.plan_description,
                    'user_id': formdata.user_id,
                    'packages': formdata.work_packages
                }

                Apis.addPlan(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        plan_name: '',
                        plan_description: '',
                        work_packages: [],
                    })
                    const raw = {
                        'user_id': user.id,
                        'project_id': formdata.project_id
                    }
                    Apis.getPlan(raw).then((res) => {
                        setPlanList(res.data.data)
                        setNewSubPlan(!newSubPlan)
                    }).catch((error) => {
                        console.log(error.response.data)
                    })

                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        }
    }

    /* Check System Password */
    const handleCheckSystemPassword = (formdata, event) => {
        setError({
            ...error,
            open: false
        })
        if (formdata.system_password === '') {
            setError({
                ...error,
                error: 'System Password is required',
                color: 'error',
                open: true
            })
        } else {
            if (formdata.id !== '') {
                if (formdata.project_lock === 1) {
                    const raw = {
                        'id': formdata.id,
                        'user_id': formdata.user_id,
                        'key': formdata.key,
                        'system_password': formdata.system_password,
                        'is_locked': !formdata.project_lock_status
                    }
                    Apis.lockProject(raw).then((res) => {
                        setValues({
                            ...values,
                            system_password: '',
                            id: '',
                            project_lock: 0,
                            plan_phase: 0,
                        })
                        setRefresh(!refresh)
                        setConfirmPassword(!confirmPassword)

                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.error,
                            color: 'error',
                            open: true
                        })
                    })
                } else if (formdata.plan_lock === 1) {
                    const raw = {
                        'id': formdata.id,
                        'user_id': formdata.user_id,
                        'key': formdata.key,
                        'system_password': formdata.system_password,
                        'is_locked': !formdata.plan_lock_status
                    }
                    Apis.lockPlan(raw).then((res) => {
                        setValues({
                            ...values,
                            system_password: '',
                            id: '',
                            plan_lock: 0,
                        })
                        const raw = {
                            'user_id': formdata.user_id,
                            'project_id': formdata.project_id
                        }
                        Apis.getPlan(raw).then((res) => {
                            setPlanList(res.data.data)
                        }).catch((error) => {
                            console.log(error.response.data)
                        })
                        setConfirmPassword(!confirmPassword)

                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.error,
                            color: 'error',
                            open: true
                        })
                    })

                }

            } else {
                const raw = {
                    'id': formdata.user_id,
                    'key': formdata.key,
                    'system_password': formdata.system_password
                }
                Apis.checkSystemInfo(raw).then((res) => {
                    setValues({
                        ...values,
                        system_password: '',
                        system_password_status: true,
                    })
                    setConfirmPassword(!confirmPassword)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        }
    }

    const handleAttributeChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        if (name === 'work_tag') {
            setAttributeValues({
                ...attribute_values,
                [name]: value,
                work_handle: '@' + value.toLowerCase().replace(/ /g, '')
            })
        }
        else {
            setAttributeValues({
                ...attribute_values,
                [name]: value,

            })
        }
    }

    const handleEditAttribute = (attribute, event) => {
        setError({
            ...error,
            error: '',
            color: '',
            open: false
        })
        setAttributeValues({
            ...attribute_values,
            attribute_name: attribute.attribute_name,
            work_tag: attribute.tag_name,
            work_handle: attribute.handle,
            id: attribute._id
        })
        setAtteributePopup(!attribute_popup)

    }

    /* Api For Delete Attribute */
    const deleteAttribute = (id, status, event) => {
        let title = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
        } else {
            title = "Are you sure want to restore?"
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (id === '' || id === undefined) {
                    setError({
                        ...error,
                        error: 'Attribute Id is required',
                        color: 'error',
                        open: true
                    })
                } else {
                    const raw = {
                        'id': id,
                        'status': status
                    }
                    Apis.deleteAttribute(raw).then((res) => {
                        setError({
                            ...error,
                            error: res.data.message,
                            color: 'success',
                            open: true
                        })
                        setRefresh(!refresh)
                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.message,
                            color: 'error',
                            open: true
                        })
                    })
                }
            }
        });
    }

    /* Api For Add Attribute */

    const handleSubmitTag = (formData) => {
        /* Update Api */
        if (formData.id !== '') {
            if (formData.work_tag === '' || formData.work_tag === undefined) {
                setError({
                    ...error,
                    error: 'Work Package tag is required',
                    color: 'error',
                    open: true
                })
            } else if (formData.work_handle === '' || formData.work_handle === undefined) {
                setError({
                    ...error,
                    error: 'Work Package handle is required',
                    color: 'error',
                    open: true
                })
            } else {
                const raw = {
                    'id': formData.id,
                    'attribute_name': formData.attribute_name,
                    'tag_name': formData.work_tag,
                    'handle': formData.work_handle,
                    'color': undefined
                }
                Apis.updateAttribute(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setRefresh(!refresh)
                    setAtteributePopup(!attribute_popup)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })

            }

            /* Add Api Call */
        } else {
            if (formData.work_tag === '' || formData.work_tag === undefined) {
                setError({
                    ...error,
                    error: 'Work Package tag is required',
                    color: 'error',
                    open: true
                })
            } else if (formData.work_handle === '' || formData.work_handle === undefined) {
                setError({
                    ...error,
                    error: 'Work  handle is required',
                    color: 'error',
                    open: true
                })
            } else {
                const raw = {
                    'attribute_name': "Work Package",
                    'tag_name': formData.work_tag,
                    'handle': formData.work_handle,
                    'color': undefined
                }
                Apis.addAttribute(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setRefresh(!refresh)
                    setAtteributePopup(!attribute_popup)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        }

    }





    const LockProject = (elm) => {
        setError({
            ...error,
            open: false
        })
        setValues({
            ...values,
            id: elm._id,
            project_lock: 1,
            project_lock_status: elm.is_locked
        })
        setConfirmPassword(!confirmPassword)

    }
    const LockPlane = (elm) => {

        setError({
            ...error,
            open: false
        })
        setValues({
            ...values,
            id: elm._id,
            plan_lock: 1,
            plan_lock_status: elm.is_locked
        })
        setConfirmPassword(!confirmPassword)
    }

    const EditProject = (elm) => {
        setError({
            ...error,
            open: false
        })
        setValues({
            ...values,
            id: elm._id,
            project_name: elm.name,
            project_description: elm.description,
            edit_card: 1,
        })
        setNewSubProject(!newSubProject)

    }

    const EditPlans = (plan) => {
        
       /*  setError({
            ...error,
            open: false
        }) */
        setValues({
            ...values,
            plan_id: plan._id,
            plan_name: plan.name,
            plan_description: plan.description,
            work_packages: plan.packages,
            edit_plan_card: 1,
        })
        setNewSubPlan(!newSubPlan)
    }

    /* Add Project */

    const handleProjectSubmit = (formdata, event) => {
        setError({
            ...error,
            open: false
        })
        if (formdata.project_name === '') {
            setError({
                ...error,
                error: 'Project name is required',
                color: 'error',
                open: true
            })
        } else {
            if (formdata.id !== '') {
                console.log(formdata)
                const raw = {
                    'id': formdata.id,
                    'name': formdata.project_name,
                    'description': formdata.project_description,
                }
                Apis.updateProject(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        id: '',
                        project_name: '',
                        project_description: '',
                        edit_card: 0
                    })
                    setRefresh(!refresh)
                    setNewSubProject(!newSubProject)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            } else {
                const raw = {
                    'name': formdata.project_name,
                    'description': formdata.project_description,
                    'user_id': formdata.user_id
                }
                Apis.addProject(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        project_name: '',
                        project_description: '',
                    })
                    setRefresh(!refresh)
                    setNewSubProject(!newSubProject)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })
            }
        }
    }

    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setValues({
            ...values,
            [name]: value,
        })
        setError({
            ...error,
            open: false
        })
    }

    const PhasePlanList = (data) => {
        setTitle('Work Structure - Phase Plans')
        setSubTitle(data.name)
        setValues({
            ...values,
            project_id: data._id,
            id: data._id,
            project_name: '',
            project_description: '',
            edit_card: 0,
        })
        const raw = {
            'user_id': data.created_by_id,
            'project_id': data._id
        }
        Apis.getPlan(raw).then((res) => {
            setPlanList(res.data.data)
        }).catch((error) => {
            console.log(error.response.data)
        })
    }






    return (
        <>
            <div id="layout-wrapper">
                <Header title={title + ' | P2IC'}></Header>
                <Alert error={error}></Alert>
                <Sidebar title='projectAttr' show='workStructure'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Work Structure</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Project Attributes</li>
                                            <li className="breadcrumb-item active">{(title === 'Work Structure') ? title : 'Work Package Tags'}</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="button-items">
                                                <button className={(title === 'Work Structure - Work Package Tags') ? "btn btn-primary  waves-effect waves-light " : "btn btn-secondary  waves-effect"} onClick={() => setTitle('Work Structure - Work Package Tags')} >Work Package Tags</button>
                                                <button className={(title === 'Work Structure' || title === 'Work Structure - Phase Plans') ? "btn btn-primary  waves-effect waves-light " : "btn btn-secondary  waves-effect"} onClick={() => setTitle('Work Structure')} >Work Structure</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pr_tagsrow">
                                <div className={(title === 'Work Structure - Work Package Tags') ? "col-lg-12" : "col-lg-12 d-none"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="project_addbtn d-flex justify-content-between">
                                                <h4 className="card-title">Work Package Tags</h4>
                                                <button type="button" className="btn btn-primary" onClick={() => setAtteributePopup(!attribute_popup)} >Add<i className="fas fa-plus"></i></button>

                                            </div>
                                            <div className="table-responsive">
                                                <WorkListTable
                                                    data={attributes}
                                                    handleDelete={deleteAttribute}
                                                    handleEdit={handleEditAttribute}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={(title === 'Work Structure') ? "col-lg-12" : "col-lg-12 d-none"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="project_addbtn d-flex justify-content-between">
                                                <h4 className="card-title">Sub Project</h4>
                                                <button type="button" className="btn btn-secondary" onClick={() => setConfirmPassword(!confirmPassword)} style={!values.system_password_status ? { display: 'block' } : { display: 'none' }}>Add a new Sub Project</button>
                                                <button type="button" className="btn btn-primary" onClick={() => setNewSubProject(!newSubProject)} style={values.system_password_status ? { display: 'block' } : { display: 'none' }}>Add a new Sub Project</button>

                                            </div>
                                            <div className="table-responsive">
                                                <SubProjectTable
                                                    data={projectList}
                                                    handleDelete={LockProject}
                                                    handleEdit={EditProject}
                                                    PhasePlanList={PhasePlanList}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={(title === 'Work Structure - Phase Plans') ? "col-lg-12" : "col-lg-12 d-none"}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="project_addbtn d-flex justify-content-between">
                                                <h4 className="card-title">{'Phase Plans > ' + subtitle} </h4>
                                                <button type="button" className="btn btn-primary" onClick={() => setNewSubPlan(!newSubPlan)}>Add a new Phase Plan</button>
                                            </div>
                                            <div className="table-responsive">
                                                <PhasePlanTable
                                                    plan_data={planList}
                                                    handleDelete={LockPlane}
                                                    handleEdit={EditPlans}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <Modal show={attribute_popup} aria-labelledby="contained-modal-title-vcenter" onHide={() => setAtteributePopup(!attribute_popup)} centered >
                <Modal.Header closeButton>
                    <Modal.Title> Work Package Tag </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <label htmlFor="recipient-name" className="col-form-label">Work Package Tag</label>
                                <input type="text" name='work_tag' onChange={handleAttributeChange} value={attribute_values.work_tag} className="form-control" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <label htmlFor="recipient-name" className="col-form-label">Handle</label>
                                <input type="text" className="form-control" name='work_handle' value={attribute_values.work_handle} onChange={handleAttributeChange} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={() => handleSubmitTag(attribute_values)}> {(attribute_values.id !== '') ? 'Update Tag' : 'Add Tag'}</button>

                    <button type="button" className="btn btn-secondary" onClick={() => setAtteributePopup(!attribute_popup)}>Close</button>
                </Modal.Footer>
            </Modal>

            {/* Confirm Password Model */}

            <Modal show={confirmPassword} aria-labelledby="contained-modal-title-vcenter" onHide={() => setConfirmPassword(!confirmPassword)}>
                <Modal.Header closeButton>
                    <Modal.Title> Confirm System Password </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <input type="password" name='system_password' value={values.system_password} onChange={handleChange} className="form-control" placeholder='Enter system password' />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => handleCheckSystemPassword(values)}>Confirm</button>
                </Modal.Footer>
            </Modal>

            {/* Add Project Model */}

            <Modal show={newSubProject} aria-labelledby="contained-modal-title-vcenter" onHide={() => setNewSubProject(!newSubProject)}>
                <Modal.Header closeButton>
                    <Modal.Title>{(values.id !== '') ? 'Edit Project' : 'New Project'}  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <label htmlFor="project-name" className="col-form-label">Sub Project</label>
                                <input type="text" id='project-name' name='project_name' value={values.project_name} onChange={handleChange} className="form-control" placeholder='Enter Project Name' />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <label htmlFor="project-description" className="col-form-label">Description</label>
                                <textarea id='project-description' name='project_description' rows="3" value={values.project_description} onChange={handleChange} className="form-control" placeholder='Enter Description' />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => handleProjectSubmit(values)}>Save</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setNewSubProject(!newSubProject)}>Cancel</button>
                </Modal.Footer>
            </Modal>

            {/* Add Plan Model */}

            <Modal show={newSubPlan} aria-labelledby="contained-modal-title-vcenter" onHide={() => setNewSubPlan(!newSubPlan)}>
                <Modal.Header closeButton>
                    <Modal.Title>{(values.plan_id !== '') ? 'Edit Plan' : 'New Plan'}  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <label htmlFor="plan-name" className="col-form-label">Phase Plan</label>
                                <input type="text" id='plan-name' name='plan_name' value={values.plan_name} onChange={handleChange} className="form-control" placeholder='Enter Plan Name' />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <label htmlFor="plan-description" className="col-form-label">Description</label>
                                <textarea id='plan-description' name='plan_description' rows="3" value={values.plan_description} onChange={handleChange} className="form-control" placeholder='Enter Description' />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <label htmlFor="plan-description" className="col-form-label">Work Packages</label>
                                <ReactSelect
                                    options={work_package_list}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                        Option
                                    }}
                                    onChange={(selected) => setValues({
                                        ...values,
                                        work_packages: selected
                                    })}
                                    allowSelectAll={true}
                                    value={values.work_packages}
                                />

                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => handlePlanSubmit(values)}>Save</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setNewSubPlan(!newSubPlan)}>Cancel</button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default Projects