import React from 'react'
import Header from '../../Layout/Header'
import Sidebar from '../../Layout/Sidebar'
import Footer from '../../Layout/Footer'
import Alert from '../../../../helpers/Alert'
import Apis from '../../../../api'
import keyBy from 'lodash/keyBy'
import Modal from 'react-bootstrap/Modal'
import { default as ReactSelect } from "react-select"
import MaterrialList from './materrialList'
import swal from 'sweetalert'

function Material() {
  const [show, setShow] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false)
  const [suppliersList, setSupplierList] = React.useState([])
  const [supplier, setSupplier] = React.useState([])
  const [attributeList, setAttributeList] = React.useState([])
  const [attribute, setAttribute] = React.useState([])
  const [error, setError] = React.useState({
    error: '',
    color: '',
    open: false
  })
  const [formData, setFormData] = React.useState({
    supplier: '',
    material_name: '',
    lead_time: '',
    uom: '',
    id: "",
    description: '',
  });

  React.useEffect(() => {
    Apis.getSuppliers().then((res) => {
      setSupplierList(res.data.data.suppliers)
    }).catch((error) => {
      console.log(error)
    })
    Apis.getmaterialAttribute().then((res) => {
      setAttributeList(res.data.data.attribute)
    }).catch((error) => {
      console.log(error)
    })
  }, [])

  React.useEffect(() => {
    let list = [];
    if (suppliersList.length !== 0) {
      suppliersList.map((elm) => {
        if (elm.status) {
          var demo = {
            value: elm._id,
            label: elm.company_name
          }
          list.push(demo)
        }
        return list
      })
    }
    setSupplier(list)

  }, [suppliersList])

  React.useEffect(() => {
    let list = [];
    if (attributeList.length !== 0) {
      attributeList.map((elm) => {
        if (elm.status) {
          var demo = {
            value: elm._id,
            label: elm.tag_name
          }
          list.push(demo)
        }
        return list
      })
    }
    setAttribute(list)

  }, [attributeList])

  React.useEffect(() => {
    if (show) {
      setError({
        ...error,
        error: '',
        color: '',
        open: false
      })
    }
  }, [show, refresh])

  const handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSupplierChange = (option, params) => {
    setFormData({
      ...formData,
      [params.name]: option,
    })
  }

  const handleSubmit = (data) => {
    console.log(data)
    if (data.supplier === '' || data.supplier === null) {
      setError({
        ...error,
        error: 'Supplier is required',
        color: 'error',
        open: true
      })
    } else if (data.material_name === '') {
      setError({
        ...error,
        error: 'Material name is required',
        color: 'error',
        open: true
      })
    } else if (data.lead_time === '') {
      setError({
        ...error,
        error: 'Lead time is required',
        color: 'error',
        open: true
      })
    } else if (data.uom === '' || data.uom === null) {
      setError({
        ...error,
        error: 'Unit of Measure is required',
        color: 'error',
        open: true
      })
    } else {
      if (data.id !== '') {
        const raw = {
          'material_name': data.material_name,
          'lead_time': data.lead_time,
          'uom': data.uom.value,
          "description": data.description,
          "supplier": data.supplier.value,
          "id": data.id,
          "timestamp": new Date().getTime()
        }
        Apis.updateMaterial(raw).then((res) => {
          setFormData({
            supplier: '',
            material_name: '',
            lead_time: '',
            uom: '',
            id: "",
            description: '',
          })
          setShow(!show)
          swal({
            title: res.data.message,
            icon: "success"
          }).then((result) => {
            setRefresh(!refresh)            
          })

        }).catch((error) => {
          setError({
            ...error,
            error: error.response.data.error,
            color: 'error',
            open: true
          })
        })

      } else {
        const raw = {
          'material_name': data.material_name,
          'lead_time': data.lead_time,
          'uom': data.uom.value,
          "description": data.description,
          "supplier": data.supplier.value,
          "timestamp": new Date().getTime()
        }
        Apis.addMaterial(raw).then((res) => {
          setFormData({
            supplier: '',
            material_name: '',
            lead_time: '',
            uom: '',
            id: "",
            description: '',
          })
          setShow(!show)
          swal({
            title: res.data.message,
            icon: "success"
          }).then((result) => {
            setRefresh(!refresh)
          })

        }).catch((error) => {
          setError({
            ...error,
            error: error.response.data.error,
            color: 'error',
            open: true
          })
        })

      }
    }
  }
  const supplierById = keyBy(suppliersList, '_id')
  const attributeById = keyBy(attributeList, '_id')

  const handleEdit = (list) => {
    setFormData({
      supplier: {
        value: list.supplier,
        label: supplierById[list.supplier]?.company_name
      },
      id: list._id,
      description: list.description,
      material_name: list.material_name,
      lead_time: list.lead_time,
      uom: {
        value: list.uom,
        label: attributeById[list.uom]?.tag_name
      },

    })
    setShow(!show)
  }


  return (
    <>
      <div id="layout-wrapper">
        <Header title='Material | P2IC'></Header>
        <Sidebar title='Material_managment' show='Material'></Sidebar>
        <Alert error={error}></Alert>
        <div className="main-content">
          <div className="page-content dashboard_content">
            <div className="container-fluid">
              <div className="page-title-box">
                <div className="row align-items-center">
                  <div className="col-md-8">
                    <h6 className="page-title">Material</h6>
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item active">Material Manager</li>
                      <li className="breadcrumb-item active">Material</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="row pr_tagsrow">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="project_addbtn d-flex justify-content-between">
                            <h4 className="card-title">Material</h4>
                            <button type="button" className="btn btn-primary" onClick={() => setShow(!show)} >Add<i className="fas fa-plus"></i></button>
                          </div>
                          <MaterrialList
                            refresh={refresh}
                            handleEdit={handleEdit}
                            suppliers={suppliersList}
                            attribute={attributeList}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
      <Modal show={show} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => setShow(!show)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{formData.id === '' ? 'Add New Material' : 'Update Material'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="supplier" className='col-form-label'>Company/Supplier</label>
                  <ReactSelect
                    options={supplier}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    value={formData.supplier}
                    name="supplier"
                    isClearable
                    onChange={handleSupplierChange}
                  />

                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="material_name" className='col-form-label'>Material Name</label>
                  <input className="form-control" type="text" name="material_name" id="material_name" value={formData.material_name} onChange={handleChange} />
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="lead_time" className='col-form-label'>Lead Time (Days)</label>
                  <input className="form-control" type="number" name="lead_time" id="lead_time" value={formData.lead_time} onChange={handleChange} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="supplier" className='col-form-label'>UoM (Unit of Measure)</label>
                  <ReactSelect
                    options={attribute}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    value={formData.uom}
                    name="uom"
                    isClearable
                    onChange={handleSupplierChange}
                  />

                </div>
              </div>

            </div>
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="mb-3">
                  <label htmlFor="description" className='col-form-label'>Description</label>
                  <input className="form-control" type="text" name="description" id="description" value={formData.description} onChange={handleChange} />
                </div>
              </div>
            </div>
          </form>

        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary" onClick={() => handleSubmit(formData)}>{formData.id === '' ? 'Add Material' : 'Update Material'}</button>
          <button type="button" className="btn btn-secondary" onClick={() => setShow(!show)}>Close</button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Material