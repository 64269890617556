import React from 'react'
import Apis from '../../../../api'
import swal from 'sweetalert'

const Attribute = ({ refresh, handleEdit }) => {
    const [attributeList, setAttributeList] = React.useState([])
    const [refreshList, setRefreshList] = React.useState(true)
    React.useEffect(() => {
        Apis.getmaterialAttribute().then((res) => {
            setAttributeList(res.data.data.attribute)
        }).catch((error) => {
            console.log(error)
        })
    }, [refresh, refreshList])

    const handleDelete = (id, status) => {
        let title = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
        } else {
            title = "Are you sure want to restore?"
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const raw = {
                    id: id
                }
                Apis.deletematerialAttribute(raw).then((res) => {
                    swal({
                        title: res.data.message,
                        icon: "success"
                    }).then((result) => {
                        setRefreshList(!refreshList)
                    })
                }).catch((error) => {
                    console.log(error)
                })
            }
        })
    }
    return (
        <>
            <div className="table-responsive">
                <table className="table table-hover table-centered table-nowrap mb-0">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">UOM</th>
                            <th scope="col">Handle</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attributeList && attributeList.map((list, index) => {
                            if (list.status) {
                                return (<>
                                    <tr>
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                            <div>{list.tag_name}</div>
                                        </td>
                                        <td>
                                            <div>{list.handle}</div>
                                        </td>
                                        <td>
                                            <div class="d-flex addremove_btn">
                                                <a className="btn btn-primary btn-sm" onClick={() => handleEdit(list)}><i className="fas fa-pencil-alt"></i></a>
                                                <a className="btn btn-primary btn-sm" onClick={() => handleDelete(list._id, 0)}><i className="fas fa-trash-alt"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </>)
                            } else {
                                return (<>
                                    <tr>
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                            <strike>{list.tag_name}</strike>
                                        </td>
                                        <td>
                                            <strike>{list.handle}</strike>
                                        </td>
                                        <td>
                                            <div class="d-flex addremove_btn">
                                                <a className="btn btn-primary btn-sm" onClick={() => handleDelete(list._id, 1)}><i className="fas fa-trash-restore"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </>)
                            }

                        })}

                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Attribute